/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "../../../examples/Cards/RotatingCard";
import RotatingCardFront from "../../../examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "../../../examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "../../../examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "../../../assets/images/toad47.png";
// import ethLogo from "assets/images/eth-logo.webp";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="$"
                title={
                  <>
                    Price: TBA
                    <br />
                    Mint day in Q3
                  </>
                }
                description="Mint your own unique Genesis Toad!"
              />
              <RotatingCardBack
                image={bgFront}
                title="Generation 1 Toads!"
                action={{
                  type: "internal",
                  route: "/sections/page-sections/page-headers",
                  label: "MINT",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}> 
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard icon="" title="Discord Community" description="" />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon=""
                  title="Genesis Toads generations"
                  description="We are currently in Generation 1 of the Genesis Toads project.
                   This collection is designed to grow with each generation. Generation 1 consists of 
                   only 1,024 Genesis Toads. Each of those Toads comes with a Genesis Tadpole that will be 
                   redeemable for one of the 2,056 Generation 2 Genesis Toads in the future. See where 
                   we're going?"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard icon="" title="About the team" description="We are a small team of three." /> 
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard icon="" title="Tokenomics" description="Out of 1,024 Gen. 1 Toads, <3% have 
                been allocated to our team, <5% have been allocated to influencers and early supporters, and 
                there are a maximum of 200 Whitelist spots." />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  ); // I decided to get rid of the "icons" as they only let us use a single letter
  //We should add our profile pics to the "About the team" section, and more information.
  //I don't think the "Discord Community" section is necessary. Maybe there is something else that can go here.
}

export default Information; 
